<template>
  <div style="min-height: 80vh;"
  class="d-flex justify-center align-center login">
    <v-card
        min-width="300"
        max-width="500"
        style="flex-grow: 1"
    >
      <v-form v-model="valid" @submit.prevent="login" class="has-bottom-page">
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>Merci de vous identifier</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email"
                        label="Email"
                        type="email"
                        :rules="emailRules"
          ></v-text-field>
          <v-text-field
              v-model="password"
              label="Mot de passe"
              type="password"
              :rules="passwordRules"
          ></v-text-field>

          <v-alert v-if="error"
                   transition="fade-transition"
                   dismissible
                   icon="mdi-alert-circle"
                   color="orange" text type="warning">
            {{error}}
          </v-alert>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" type="submit" color="primary" depressed>
            connexion
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>

</template>

<script>
import UserModel from "@/Models/UserModel";
export default {
  name: "login",
  data(){
    return{
      /**
       * true si le formulaire peut être envoyé
       */
      valid: false,
      email: '',
      password:'',
      showPw:false,
      persistentHint: false,
      wait: false,
      error: false,
    }
  },
  computed:{
    passwordRules(){
      return UserModel.passwordRules();
    },
    emailRules(){
      return UserModel.emailRules();
    }
  },
  methods: {
    login() {
      let me = this;
      me.wait = true;
      me.error = false;
      this.$api.login(
          this.email,
          this.password,
          function (user) {
            console.log("success",user)
            me.wait = false;
            me.$router.push({name:"Home"});
            setTimeout(function (){
              document.location.reload();
            },200)

          },
          function (data) {
            console.log("error",data.errors)
            me.error = data.errors[0].message;
            me.wait = false;
          }
      )
    }
  }
}
</script>

<style lang="less">
.login{
  input:-webkit-autofill
  {
    //-webkit-text-fill-color: yellow !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
</style>